import React from 'react'
import moment from 'moment'
import { useLocationOptions } from '../hooks/useLocationOptions'
import { CmsRoomTypes } from '../services/cms'
import { RoomTypeAvailable, Location } from '../../../../shared/types/cms'
import { dateFormat } from '../../../../shared/config/date'
import { navigate } from 'gatsby'
import { getNextValidDate, isValidBookingDate } from '../components/datepicker'
import { useLocation } from '@reach/router'
import { RoomSorting } from '../components/listings-sort-selector'
export interface SearchInterface {
  location: string
  setLocation: (value: string) => void
  startDate: string
  setStartDate: (value: string) => void
  roomTypes: RoomTypeAvailable[]
  highlightedRoom: number
  setHighlightedRoom: (value: number) => void
  mapVisible: boolean
  setMapVisible: (value: boolean) => void
  currentPage: number
  setCurrentPage: (value: number) => void
  pageSize: number
  doSearch: () => void
  getUrlSearchParams: (_?: Record<string, string>) => string
  searchInitialized: boolean
  isSearching: boolean
  setSelectedSort: (_: RoomSorting) => void
  selectedSort: RoomSorting
  locationChanged: boolean
  locationObject?: Location
}

export const PAGE_SIZE_MAP_VISIBLE = 6
export const PAGE_SIZE_MAP_HIDDEN = 12

const defaultState: SearchInterface = {
  location: ``,
  startDate: getNextValidDate(),
  setLocation: () => null,
  setStartDate: () => null,
  roomTypes: [],
  highlightedRoom: -1,
  setHighlightedRoom: () => null,
  mapVisible: true,
  setMapVisible: () => null,
  currentPage: 0,
  setCurrentPage: () => null,
  pageSize: PAGE_SIZE_MAP_VISIBLE,
  doSearch: () => null,
  getUrlSearchParams: () => ``,
  searchInitialized: false,
  isSearching: false,
  setSelectedSort: () => null,
  selectedSort: RoomSorting.None,
  locationChanged: false,
}

export const SearchContext = React.createContext(defaultState)

const SearchProvider: React.FC = ({ children }) => {
  const { locations } = useLocationOptions()
  const windowLocation = useLocation()
  const [location, setLocation] = React.useState(defaultState.location)
  const [startDate, setStartDate] = React.useState(defaultState.startDate)
  const [roomTypes, setRoomTypes] = React.useState<RoomTypeAvailable[]>(defaultState.roomTypes)
  const [highlightedRoom, setHighlightedRoom] = React.useState(defaultState.highlightedRoom)
  const [currentPage, setCurrentPage] = React.useState(defaultState.currentPage) // First page is 0
  const [mapVisible, setMapVisible] = React.useState(defaultState.mapVisible)
  const [inputHasChanged, setInputHasChanged] = React.useState(true)
  const [locationChanged, setLocationChanged] = React.useState(false)
  const [searchInitialized, setSearchInitialized] = React.useState(defaultState.searchInitialized)
  const [isSearching, setIsSearching] = React.useState(defaultState.isSearching)
  const [selectedSort, setSelectedSort] = React.useState<RoomSorting>(defaultState.selectedSort)
  const [locationObject, setLocationObject] = React.useState<Location>()

  const pageSize = defaultState.mapVisible ? PAGE_SIZE_MAP_VISIBLE : PAGE_SIZE_MAP_HIDDEN
  const getUrlSearchParams: (_?: Record<string, string>) => string = (otherParams = {}) => {
    const searchParams: Record<string, string> = { start: startDate }
    if (location !== ``) searchParams.location = location
    if (currentPage > 0) searchParams.page = currentPage.toString()

    const urlParams = new URLSearchParams({ ...searchParams, ...otherParams })
    return `?` + urlParams.toString()
  }

  const updateUrlParams = () => {
    if (typeof window === `undefined`) return

    // Extract params not related to search to not overwrite them
    const otherParams: Record<string, string> = {}
    const currentParams = new URLSearchParams(window.location.search)
    currentParams.forEach((value, key) => {
      if (![`start`, `location`].includes(key)) {
        otherParams[key] = value
      }
    })

    const searchParams = getUrlSearchParams(otherParams)

    history.replaceState({}, document.title, window.location.pathname + searchParams)
  }

  const doSearch = () => {
    if (!/listings\/?$/i.test(window.location.pathname)) {
      navigate(`/listings/` + getUrlSearchParams())
      return
    }

    if (!inputHasChanged) return
    if (startDate === `` || location === ``) return
    setSearchInitialized(true)
    setIsSearching(true)

    CmsRoomTypes.getAvailable(location, startDate)
      .then((roomTypes) => {
        setInputHasChanged(false)
        setLocationObject(locations.find((loc) => loc.id === location) as Location)

        if (selectedSort === RoomSorting.PriceAscending) {
          roomTypes.sort((a, b) => a.priceData.value - b.priceData.value)
        } else if (selectedSort === RoomSorting.PriceDescending) {
          roomTypes.sort((a, b) => b.priceData.value - a.priceData.value)
        }

        setRoomTypes(roomTypes)
      })
      .catch((e: Error) => {
        console.log(e.message)
      })
      .finally(() => {
        setIsSearching(false)
      })
  }

  React.useEffect(() => {
    // Attempt to read search parameters from the url
    const urlSearchString = window.location.search
    const urlParams = new URLSearchParams(urlSearchString)
    let startDate = getNextValidDate()
    const urlStartDate = urlParams.get(`start`)
    if (urlStartDate !== null) {
      try {
        startDate = moment(urlStartDate, dateFormat).format(dateFormat)
        if (!isValidBookingDate(startDate)) startDate = getNextValidDate()

        setStartDate(startDate)
      } catch {}
    } else {
      setStartDate(getNextValidDate())
    }

    let currentPage = 0
    const urlCurrentPage = urlParams.get(`page`)
    if (urlCurrentPage !== null) {
      try {
        currentPage = Number(urlCurrentPage)
        if (!Number.isNaN(currentPage)) {
          setCurrentPage(currentPage)
        }
      } catch {}
    }

    const urlCurrentLocation = urlParams.get(`location`)
    if (urlCurrentLocation !== null) {
      try {
        if (urlCurrentLocation === ``) {
          setLocation(locations[0].id.toString())
        } else {
          setLocation(Number(urlCurrentLocation).toString())
        }
      } catch {}
    } else {
      setLocation(locations[0].id.toString())
    }
  }, [windowLocation])

  React.useEffect(() => {
    setInputHasChanged(true)
    updateUrlParams()
  }, [location, startDate])

  React.useEffect(() => {
    if (selectedSort === RoomSorting.PriceAscending) {
      roomTypes.sort((a, b) => a.priceData.value - b.priceData.value)
      setRoomTypes([...roomTypes])
    } else if (selectedSort === RoomSorting.PriceDescending) {
      roomTypes.sort((a, b) => b.priceData.value - a.priceData.value)
      setRoomTypes([...roomTypes])
    }
  }, [selectedSort])

  const state: SearchInterface = {
    location,
    startDate,
    setLocation: (v) => {
      setLocationChanged(true)
      setLocation(v)
    },
    setStartDate,
    roomTypes,
    highlightedRoom,
    setHighlightedRoom,
    mapVisible,
    setMapVisible,
    pageSize,
    currentPage,
    setCurrentPage,
    doSearch,
    getUrlSearchParams,
    searchInitialized,
    isSearching,
    selectedSort,
    setSelectedSort,
    locationChanged,
    locationObject,
  }

  return <SearchContext.Provider value={state}>{children}</SearchContext.Provider>
}

export default SearchProvider
